import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router){

  }
  access = false
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      let user = JSON.parse(localStorage.getItem('currentUser'))
      let role = user["resultData"].role
      console.log('this.access', this.access)
      const allowedRoles = ["admin", "operation", "telesale", "account","marketing","logistic"];
      this.access = role.some((rol) => allowedRoles.includes(rol.name.toLowerCase()));
      return this.access
  }
}
